import React, { useEffect, lazy, Suspense, useState } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AOS from "aos";
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/customizer.min.css';

const Home = lazy(() => import("./pages/Home"))
const NoPage = lazy(() => import("./pages/Authentication/NoPage"))
const Layout = lazy(() => import("./components/Layout"))
const Complaints = lazy(() => import("./pages/Complaints"))
const MyProfile = lazy(() => import("./pages/MyProfile"))
const RolesPermissions = lazy(() => import("./pages/MyTeam/RolesPermissions"))
const MyOutlets = lazy(() => import("./pages/MyOutlets"))
const Feedbacks = lazy(() => import("./pages/Feedbacks"))
const AllNotifications = lazy(() => import('./pages/AllNotifications'))
const AllMessages = lazy(() => import('./pages/AllMessages'))
const SignIn = lazy(() => import("./pages/Authentication/SignIn"))
const DispendingUnits = lazy(() => import("./pages/DispendingUnits"))
const WorkImages = lazy(() => import("./pages/WorkImages"))
const Contacts = lazy(() => import("./pages/Contacts"))
const Tutorials = lazy(() => import("./pages/Tutorials"))
const Reports = lazy(() => import("./pages/Reports"))
const AllSurvey = lazy(() => import("./pages/Survey/AllSurvey"))
const CreateSurvey = lazy(() => import("./pages/Survey/CreateSurvey"))
const ViewSurvey = lazy(() => import("./pages/Survey/ViewSurvey"))
const ResponseSurvey = lazy(() => import("./pages/Survey/ResponseSurvey"))
const AssignedSurvey = lazy(() => import("./pages/Survey/AssignedSurvey"))
const RequestSurvey = lazy(() => import("./pages/Survey/RequestSurvey"))
const EarthingTestingCertificate = lazy(() => import("./pages/EarthingTestingCertificate"))
const AssignedComplaint = lazy(() => import("./components/Complaints/AssignedComplaint"))
const NewComplaints = lazy(() => import("./components/Complaints/NewComplaints"))
const CompletedComplaint = lazy(() => import("./components/Complaints/CompletedComplaint"))
const AllComplaints = lazy(() => import("./components/Complaints/AllComplaints"))


const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  AOS.init();

  // theme color
  const [color, setColor] = useState(localStorage.getItem("color"));

  const changeBg = (themeColor) => {
    localStorage.setItem('color', themeColor);
    document.body.classList.remove('light');
    document.body.classList.remove('dark');
    document.body.classList.remove('semi-dark');
    document.body.classList.add(themeColor);
    setColor(themeColor)
  };

  useEffect(() => {
    const color = localStorage.getItem('color');
    document.body.classList.remove('light');
    document.body.classList.remove('dark');
    document.body.classList.remove('semi-dark');
    setColor(color)
    document.body.classList.add(color);
  }, [])

  useEffect(() => {
    const selected = JSON.parse(localStorage.getItem('body-bg'));
    const bg = `rgba(${selected?.r || 233},${selected?.g || 233},${selected?.b || 240},${selected?.a || 1})`
    document.documentElement.style.setProperty('--bs-indigo', bg);
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" href={favicon}></link> */}
        <title>CMS</title>
      </Helmet>
      <BrowserRouter>
        <Suspense fallback={<div className='vh-100 d-align'><img className='img-fluid' src='https://flevix.com/wp-content/uploads/2019/07/Curve-Loading.gif' alt='Loading' /></div>}>
          <Routes>
            <Route path="/" element={<Layout changeBg={changeBg} checked={color} />}>
              <Route index element={<Home />} />
              <Route path="/Complaints" element={<Complaints />} />
              <Route path="/AllComplaints" element={<AllComplaints />} />
              <Route path="/NewComplaints" element={<NewComplaints />} />
              <Route path="/AssignedComplaint" element={<AssignedComplaint />} />
              <Route path="/CompletedComplaint" element={<CompletedComplaint />} />
              <Route path="/DispendingUnits" element={<DispendingUnits />} />
              <Route path="/WorkImages" element={<WorkImages />} />
              <Route path="/Contacts" element={<Contacts />} />
              <Route path="/Tutorials" element={<Tutorials />} />
              <Route path="/Reports" element={<Reports />} />
              <Route path="/AllSurvey" element={<AllSurvey />} />
              <Route path="/CreateSurvey" element={<CreateSurvey />} />

              <Route path="/ResponseSurvey" element={<ResponseSurvey />} />
              <Route path="/AssignedSurvey" element={<AssignedSurvey />} />
              <Route path="/RequestSurvey" element={<RequestSurvey />} />
              <Route path="/MyOutlets" element={<MyOutlets />} />
              <Route path="/RolesPermissions" element={<RolesPermissions />} />
              <Route path="/EarthingTestingCertificate" element={<EarthingTestingCertificate />} />
              <Route path="/Feedbacks" element={<Feedbacks />} />
              <Route path="/MyProfile" element={<MyProfile />} />
              <Route path="/AllNotifications" element={<AllNotifications />} />
              <Route path="/AllMessages" element={<AllMessages />} />
            </Route>
            <Route path="/SignIn" element={<SignIn />} />
            <Route path="/ViewSurvey/:id" element={<ViewSurvey />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};
export default App;
